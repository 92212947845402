(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbCta.constant:EdenredCTAClient
   *
   * @description
   *
   */
  angular
    .module('wbCta')
    .constant('EdenredCTAClient', window.EdenredCTAClient);
}());
